import React from 'react'

import string_to_slug from '../../utils/slug'
import styled from 'styled-components'

export default function TOC({ items }) {
    const content = items.map((item, index) => {
        if (item.data.Type.includes('H2')) {
            return (
                <li key={index}>
                    <a href={'#' + string_to_slug(item.data.Content)}>
                        {item.data.Content}
                    </a>
                </li>
            )
        }
    })

    return (
        <TOCContainer className="toc">
            <h2>Inhaltsverzeichnis</h2>
            <ul>{content}</ul>
        </TOCContainer>
    )
}

const TOCContainer = styled.div`
    h2 {
        font-family: 'SawtonBauhaus';
        font-weight: 300;
        font-size: 1.222rem;
        padding-top: 0;
    }

    ul {
        font-size: 0.888rem;
        padding: 0;
        list-style: none;
        font-family: 'SawtonBauhaus';
        font-weight: 300;

        li {
            margin-top: 0.75rem;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
`
