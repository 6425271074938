import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'

import Markdown from '../../utils/markdown'
import { isType } from '../../utils/typecheck'

interface AuthorProps {
    author: {
        text?: JSX.Element
        Autor_Name?: string
        E_Mail?: string
        Headline?: string
        LinkedIn?: string
        Telefon?: string
    }
}

export default function Author({ author }: AuthorProps) {
    return (
        <AuthorContainer className="author-box">
            <h2>{author.Headline}</h2>
            <p className="name">{author.Autor_Name}</p>
            {author.text && <p>{author.text}</p>}
            {author.E_Mail && (
                <a href={`mailto:${author.E_Mail.split(' | ')[0]}`}>
                    {author.E_Mail.split(' | ')[1] ?? author.E_Mail}
                </a>
            )}
            {author.Telefon && (
                <a href={`tel:${author.Telefon.split(' | ')[0]}`}>
                    {author.Telefon.split(' | ')[1] ?? author.Telefon}
                </a>
            )}
            {author.LinkedIn && (
                <a
                    target="_blank"
                    href={`${
                        author.LinkedIn.split(' | ')[0].includes('https')
                            ? ''
                            : 'https://'
                    }${author.LinkedIn.split(' | ')[0]}`}
                >
                    {author.LinkedIn.split(' | ')[1] ?? author.LinkedIn}
                </a>
            )}
        </AuthorContainer>
    )
}

const AuthorContainer = styled.aside`
    font-family: 'SawtonBauhaus';

    h2 {
        font-family: 'SawtonBauhaus';
        font-size: clamp(1.25rem, calc(1.0392rem + 0.9486vw), 1.777rem);
    }

    .name {
        font-size: 1rem;
    }

    p {
        font-size: 0.888rem;
    }

    a {
        font-size: 0.888rem;
        text-decoration: underline;
        margin-top: 0.5rem;
        display: block;
        width: max-content;

        &:hover {
            text-decoration: none;
        }
    }
`
