import React from 'react'
import styled from 'styled-components'

import { isType } from '../../utils/typecheck'
import { VideoPlayer } from '../videoPlayer'

export default function TwoImages({
    type,
    images,
    figcaptions,
}: {
    type: string
    images: {
        url: string
        type: string
    }
    figcaptions: string
}) {
    let typeClass

    switch (true) {
        case isType(type, 'Bilderstrecke-breit-1'):
            typeClass = 'two-images-wide-1'
            break

        case isType(type, 'Bilderstrecke-breit-2'):
            typeClass = 'two-images-wide-2'
            break

        case isType(type, 'Bilderstrecke-schmal-1'):
            typeClass = 'two-images-small-1'
            break

        case isType(type, 'Bilderstrecke-schmal-2'):
            typeClass = 'two-images-small-2'
            break
    }

    return (
        <TwoImagesContainer className={'image-group ' + typeClass}>
            <figure className="image-1">
                <img
                    alt=""
                    srcSet={images?.[0]?.srcSet}
                    sizes={images?.[0]?.sizes}
                />
                {figcaptions && figcaptions.split('|')[0] && (
                    <figcaption>{figcaptions.split('|')[0]}</figcaption>
                )}
            </figure>
            <figure className="image-2">
                <img
                    alt=""
                    srcSet={images?.[1]?.srcSet}
                    sizes={images?.[1]?.sizes}
                />
                {figcaptions && figcaptions.split('|')[1] && (
                    <figcaption>{figcaptions.split('|')[1]}</figcaption>
                )}
            </figure>
        </TwoImagesContainer>
    )
}

const TwoImagesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);

    figure.image-1,
    figure.image-2 {
        margin-bottom: 2rem;

        @media (min-width: ${(props) => props.theme.md}) {
            margin: 0;
        }
    }

    &.two-images-wide-1 {
        .image-1 {
            grid-column: 2 / -3;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 2 / -15;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 4 / -15;
            }
        }

        .image-2 {
            grid-column: 5 / -2;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 14 / -2;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 14 / -3;
            }
        }
    }

    &.two-images-wide-2 {
        .image-1 {
            grid-column: 2 / -3;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 2 / -13;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 3 / -13;
            }
        }

        .image-2 {
            grid-column: 5 / -2;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 16 / -2;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 16 / -3;
            }
        }
    }

    &.two-images-small-1 {
        .image-1 {
            grid-column: 3 / -2;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 5 / -11;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 6 / -10;
            }
        }

        .image-2 {
            grid-column: 2 / -5;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 17 / -5;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 18 / -4;
            }
        }
    }

    &.two-images-small-2 {
        .image-1 {
            grid-column: 3 / -2;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 5 / -13;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 6 / -12;
            }
        }

        .image-2 {
            grid-column: 5 / -2;

            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 15 / -5;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 16 / -4;
            }
        }
    }

    .text-instead-of-image {
        grid-row: 2 /3;
        grid-column: 3 / -2;
        margin-bottom: 1rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 16 / -2;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 16 / -3;
        }

        h3 {
            font-weight: 300;
            font-size: clamp(1.25rem, calc(1.0392rem + 0.9486vw), 1.777rem);
        }
    }
`
