import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import string_to_slug from '../utils/slug'
import { isType } from '../utils/typecheck'
import Layout from '../components/layout'
import TOC from '../components/simple/toc'
import ImageGroup from '../components/sections/imageGroup'
import Author from '../components/simple/author'
import Markdown from '../utils/markdown'
import TwoImages from '../components/sections/twoImages'
import NextArticles from '../components/sections/nextArticles'
import { WebVideo } from '../components/webVideo'
// import SEO from '../components/seo'

export const query = graphql`
    query GetArticle($Path: String!) {
        allAirtable(
            filter: { table: { eq: "Artikel" }, data: { Slug: { eq: $Path } } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Type
                    Content
                    Attachments {
                        src
                        srcSet
                        sizes
                    }
                    Vimeo_ID
                    YouTube_ID
                    Autoren {
                        data {
                            Headline
                            Autor_Name
                            E_Mail
                            LinkedIn
                            Telefon
                        }
                    }
                }
            }
        }
    }
`

export default ({ data }) => {
    const items = data.allAirtable.nodes
    const content = items.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'H1'):
                return <h1 key={index}>{formattedContent}</h1>

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} id={string_to_slug(item.data.Content)}>
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Teasertext'):
                return (
                    <React.Fragment key={index}>
                        <p className="teaser">{formattedContent}</p>
                        <TOC items={items} />
                    </React.Fragment>
                )

            case isType(item.data.Type, 'Tags'):
                return (
                    <div key={index} className="tags">
                        {formattedContent}
                    </div>
                )

            case isType(item.data.Type, 'Titelbild') &&
                !isType(item.data.Type, 'Video'):
                return (
                    <figure key={index} className="cover-picture">
                        <img
                            alt=""
                            srcSet={item.data.Attachments[0].srcSet}
                            sizes={item.data.Attachments[0].sizes}
                        />
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Titelbild') &&
                isType(item.data.Type, 'Video'):
                return (
                    <div key={index} className="cover-video">
                        <WebVideo
                            type={item.data.Vimeo_ID ? 'vimeo' : 'youtube'}
                            videoId={item.data.Vimeo_ID ?? item.data.YouTube_ID}
                            figcaption={formattedContent}
                            thumbnail={item.data.Attachments?.[0]?.url}
                        />
                    </div>
                )

            case !isType(item.data.Type, 'Titelbild') &&
                isType(item.data.Type, 'Video'):
                return (
                    <div key={index} className="single-image">
                        <WebVideo
                            type={item.data.Vimeo_ID ? 'vimeo' : 'youtube'}
                            videoId={item.data.Vimeo_ID ?? item.data.YouTube_ID}
                            figcaption={formattedContent}
                            thumbnail={item.data.Attachments?.[0]?.url}
                        />
                    </div>
                )

            case isType(item.data.Type, 'Projektdaten'):
                return (
                    <div key={index} className="project-data">
                        {formattedContent}
                    </div>
                )

            case isType(item.data.Type, 'Fließtext'):
                return <p key={index}>{formattedContent}</p>

            case isType(item.data.Type, 'Zitat'):
                return (
                    <figure className="quote" key={index}>
                        <blockquote>{formattedContent}</blockquote>
                        {isType(items[index + 1].data.Type, 'BU') && (
                            <figcaption>
                                <cite>
                                    <Markdown
                                        content={items[index + 1].data.Content}
                                    />
                                </cite>
                            </figcaption>
                        )}
                    </figure>
                )

            case isType(item.data.Type, 'Infobox'):
                return (
                    <aside key={index} className="infobox">
                        {formattedContent}
                    </aside>
                )

            case isType(item.data.Type, 'Bild'):
                return (
                    <figure key={index}>
                        <img
                            alt=""
                            srcSet={item.data.Attachments[0].srcSet}
                            sizes={item.data.Attachments[0].sizes}
                        />
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Bilderstrecke-breit-1'):
            case isType(item.data.Type, 'Bilderstrecke-breit-2'):
            case isType(item.data.Type, 'Bilderstrecke-schmal-1'):
            case isType(item.data.Type, 'Bilderstrecke-schmal-2'):
                return (
                    <TwoImages
                        key={index}
                        type={item.data.Type}
                        images={item.data.Attachments}
                        figcaptions={item.data.Content}
                    />
                )

            case isType(item.data.Type, 'Autor'):
                return (
                    <Author
                        key={index}
                        author={{
                            text: formattedContent,
                            ...item.data.Autoren[0].data,
                        }}
                    />
                )

            case isType(item.data.Type, 'Magazin-Slugs'):
                return (
                    <NextArticles
                        key={index}
                        heading={
                            'Das hat sie begeistert — dann schauen sie sich weiter in unserem Magazin voller Ideen und Erlebnisse um'
                        }
                        articlesCount={3}
                        slugs={item.data.Content?.split(' | ')}
                    />
                )
        }
    })

    return (
        <ArticleContainer>
            <Layout>
                {/* <SEO title="Page two" /> */}
                <span className="kicker">Magazin</span>
                {content}
            </Layout>
        </ArticleContainer>
    )
}

const ArticleContainer = styled.div`
    .cover-picture,
    .cover-video {
        grid-column: 3 / -3;
        margin-top: 0;
        margin-bottom: 0;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }
    }
`
